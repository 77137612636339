<template>
  <div class="menu-slide-container">
    <transition name="backdrop-fade">
      <div v-show="isDrawerShown" class="backdrop" @click="closeDrawer()" />
    </transition>
    <transition name="menu-slide">
      <div
        v-show="isDrawerShown"
        class="menu-wrapper"
        :class="drawerOptions.menuPositionClass"
      >
        <div
          class="menu-slide-header container d-flex justify-content-center justify-content-lg-between align-items-center"
        >
          <div class="col-4">
            <CloseBtn :on-click="closeDrawer" />
          </div>
          <div class="heading fz-14 col-8 text-end" data-nw="menu-title">
            {{ $label(drawerOptions.title) }}
          </div>
        </div>
        <component
          :is="drawerOptions.component"
          v-bind="drawerOptions.props"
          class="menu__content bc-p-18"
        ></component>
        <component
          :is="drawerOptions.footer"
          v-if="drawerOptions.footer"
          class="menu__footer"
        ></component>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProfileBirthdateInformation from '~/components/account/ProfileBirthdateInformation'
import ProfileAccountInformation from '~/components/account/ProfileAccountInformation'
import ProfileAddressInformation from '~/components/account/ProfileAddressInformation'
import ProfileResetPassword from '~/components/account/ProfileResetPassword'
import BaseLogin from '~/components/BaseLogin'
import BaseMenuUser from '~/components/BaseMenuUser'
import CartDrawer from '~/components/shopCart/CartDrawer'
import CartDrawerFooter from '~/components/shopCart/CartDrawerFooter'
import CloseBtn from '~/components/buttons/CloseBtn'
import ArchiveCategories from '~/components/archive/ArchiveCategories'
import MobileMenu from '~/components/menu/MobileMenu'
import { useDrawer } from '~/composables/useDrawer'

export default {
  components: {
    BaseLogin,
    BaseMenuUser,
    ProfileResetPassword,
    ProfileBirthdateInformation,
    ProfileAddressInformation,
    ProfileAccountInformation,
    CartDrawer,
    CartDrawerFooter,
    CloseBtn,
    ArchiveCategories,
    MobileMenu,
  },
  setup() {
    const { closeDrawer, isDrawerShown } = useDrawer()

    return {
      closeDrawer,
      isDrawerShown,
    }
  },
  data() {
    return {
      scrollTop: 0,
      lockClass: 'scroll-lock',
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      loggedInUser: 'loggedInUser',
      drawerOptions: 'drawerOptions',
    }),
  },
  watch: {
    isDrawerShown(state) {
      state ? this.lockBodyScroll() : this.unlockBodyScroll()
    },
  },
  mounted() {
    document.body.addEventListener('keydown', this.closeOnEscapeKey)
  },
  beforeUnmount() {
    document.body.removeEventListener('keydown', this.closeOnEscapeKey)
  },
  methods: {
    closeOnEscapeKey(event) {
      if (event.key === 'Escape') {
        this.closeDrawer()
      }
    },
    lockBodyScroll() {
      this.scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop

      document.body.classList.add(this.lockClass)
      document.body.style.marginTop = `-${this.scrollTop}px`
    },
    unlockBodyScroll() {
      document.body.classList.remove(this.lockClass)
      document.body.removeAttribute('style')
      window.scroll({ left: 0, top: this.scrollTop, behavior: 'instant' })
    },
  },
}
</script>

<style lang="scss" scoped>
@keyframes fadeBackdrop {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.3;
  }
}

.backdrop {
  display: flex;
  opacity: 0.3;
  background: $grey-900;
  bottom: 0;
  height: 100%;
  width: 100%;
  pointer-events: all;
  position: fixed;
  z-index: 15;
}

.menu-wrapper {
  background: map-get($theme-colors, secondary);
  width: 360px;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 20;
  pointer-events: inherit;
  display: flex;
  flex-direction: column;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}

.menu-slide-header {
  background-color: #fbfaf9;
  min-height: 57px;
  height: 57px;
}

.menu-slide-sticky-bottom {
  background: map-get($theme-colors, secondary);
  height: 150px;
  width: 100%;
}

.menu-slide-content-wrapper {
  height: auto;
  max-height: calc(100% - 246px);
  overflow: auto;
}

.menu-slide-content {
  height: 100%;
  min-height: 100%;
}

.menu__content {
  animation-delay: 0.3s;
  height: 100%;
  opacity: 1;
  overflow-y: scroll;
}

.menu__footer {
  background-color: #fbfaf9;
  display: flex;
  justify-content: center;
  padding: 18px;
}

@include media-breakpoint-down(sm) {
  .menu-wrapper {
    min-width: 100%;
    width: 100%;
  }

  .backdrop {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .menu-slide-header {
    height: 96px;
  }

  .menu__content {
    animation: fadeIn 0.3s forwards ease-out;
  }

  .right.menu-slide-enter-active {
    animation: slideInFromRight 0.3s forwards ease-out;
  }

  .right.menu-slide-leave-active {
    animation: slideInFromRight 0.3s reverse ease-in;
  }

  .left.menu-slide-enter-active {
    animation: slideInFromLeft 0.3s forwards ease-out;
  }

  .left.menu-slide-leave-active {
    animation: slideInFromLeft 0.3s reverse ease-in;
  }
}
</style>
